<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-btn
          @click="imageUploadClick"
          elevation="2"
          dark
          left
          :loading="image_loading"
          >{{ Photo_Upload_Btn_Text }}
          <v-icon right>{{ Photo_Upload_Btn_Icon }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <!--Upload preview-->
      <v-col cols="12" sm="12" md="6">
        <v-card width="356">
          <div v-if="image_loading || !!this.src">
            <v-img
              class="preview"
              height="250"
              width="356"
              :lazy-src="!!this.src ? this.src : img1"
              :src="!!this.src ? this.src : img1"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    :value="uploadValue"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
          <input
            type="file"
            ref="input1"
            style="display: none"
            @change="previewImage"
            accept="image/*"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import firebase from "firebase";
export default {
  name: "FormImageUpload",
  props: ["input"],
  data() {
    return {
      image_loading: false,
      img1: null,
      src: this.input,
      imageData: null,
      uploadValue: null,
    };
  },
  methods: {
    imageUploadClick() {
      this.$refs.input1.click();
    },
    previewImage(event) {
      this.image_loading = true;
      this.uploadValue = 0;
      this.img1 = null;
      this.imageData = event.target.files[0];
      this.onUpload();
    },
    onUpload() {
      this.img1 = null;
      const storageRef = firebase
        .storage()
        .ref("ecommerce-category-img/" + `${this.imageData.name}`)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.img1 = url;
            this.image_loading = false;
            //Sets product image to the value of URL in firebase storage
            this.src = url;
          });
        }
      );
    },
  },
  computed: {
    Photo_Upload_Btn_Text() {
      return this.src === null ? "Choose Photo" : "Change Photo";
    },
    Photo_Upload_Btn_Icon() {
      return this.src === null ? "mdi-upload" : "mdi-camera-retake";
    },
  },
  watch: {
    img1: function (value) {
      this.$emit("passChange", value);
    },
  },
};
</script>